<template>
  <v-container
    fluid
    class="pr-0 pb-0"
    :class="{ 'app-height-global' : loaderState }"
  >
    <Loader
      v-if="loaderState"
      class="pt-0"
    />
    <template v-else>
      <Header
        :status="tab"
        :active-count="activeCount"
        :archived-count="archivedCount"
        @update-status="updateFilterStatus"
        @create-shared-step="onCreateSharedStep"
        @close="closeDetail"
      />

      <v-row
        class="align-start"
        dense
      >
        <v-col
          :cols="isDetailMode ? 7 : 12"
        >
          <v-card
            v-if="hasSteps || onFetchDataState"
            class="pa-6 mt-3 app-height-global"
            rounded="lg"
            elevation="0"
            width="100%"
            :class="{ 'd-flex' : !hasSteps && !onFetchDataState, 'align-center': !hasSteps && !onFetchDataState }"
          > 
            <v-row
              justify="space-between"
              class="align-center"
            >
              <v-col
                cols="12"
                md="auto"
                class="d-flex align-center"
              >
                <v-text-field
                  v-model="searchFilter"
                  :loading="loading"
                  prepend-inner-icon="mdi-magnify"
                  :placeholder="$t('search_by_name')"
                  class="text-field mt-0 pa-0 mr-3 rounded-lg field-theme custom-prepend pa-0"
                  height="40"
                  background-color="#F9F9FB"
                  hide-details
                >
                  <template v-slot:prepend-inner>
                    <SearchIcon />
                  </template>
                </v-text-field>

                <FilterDialog @update-filter="updateFilter" />
              </v-col>

              <v-col
                cols="12"
                md="auto"
                class="mr-3"
              >
                <v-row
                  justify="end"
                  class="align-center"
                >
                  <SettingsMenu 
                    table-type="sharedSteps"
                  />
                </v-row>
              </v-col>
            </v-row>

            <SharedStepTable
              :headers="filteredHeaders"
              :items="filteredSteps"
              item-key="uid"
              @edit="handleEditItem"
              @delete="handleDeleteItem"
              @archive="handleArchiveItem"
              @unarchive="handleUnArchiveItem"
              @input="handleInput"
              @row-click="handleClickRow"
            />

            <v-row
              v-if="selectedItems.length > 0"
              justify="end"
              class="action-btn-wrapper px-6 py-4"
            >
              <v-btn
                width="140"
                color="danger"
                class="text-capitalize rounded-lg mr-3 white--text btn-theme"
                depressed
                @click="deleteSharedSteps"
              >
                {{ $t('delete') }}
              </v-btn>
              <v-btn
                v-if="tab == 'active'"
                width="140"
                color="primary"
                class="text-capitalize rounded-lg btn-theme"
                depressed
                @click="handleChangeActiveStatuses(true)"
              >
                {{ $t('archive') }}
              </v-btn>
              <v-btn
                v-else
                width="140"
                class="text-capitalize rounded-lg btn-theme"
                depressed
                color="primary"
                @click="handleChangeActiveStatuses(false)"
              >
                {{ $t('unarchive') }}
              </v-btn>
            </v-row>
          </v-card>
          <v-card
            v-else
            class="mt-3 app-height-global d-flex align-center justify-center"
            rounded="lg"
            elevation="0"
            width="100%"
          >
            <Placeholder
              :title="$t('sharedStepPage.emptyTitle')"
              :btn-text="$t('sharedStepPage.createSharedStep')"
              @action="onCreateSharedStep"
            />
          </v-card>
        </v-col>
        <v-col
          v-if="isDetailMode"
          cols="5"
        >
          <SharedStepDetail
            :detail-item="currentItem"
            :has-previous="currentFilteredIndex > 0"
            :has-next="currentFilteredIndex < filteredSteps.length - 1"
            @previous="navigateToPrevious"
            @next="navigateToNext"
            @edit="handleEditItem"
            @close="closeDetail"
          />
        </v-col>
      </v-row>
    </template>
    <CreateUpdateDialog
      v-model="showCreateUpdateDialog"
      :data="selectedStep"
      @create-shared-step="createSharedStep"
      @update-shared-step="updateSharedStep"
      @close-dialog="showCreateUpdateDialog = false"
    />

    <confirm-dialog
      v-model="showDeleteConfirmDialog"
      :title="$t('sharedStepPage.deleteConfirm', { name: selectedStep.name })"
      :description="$t('sharedStepPage.deleteDescription')"
      :actions="[
        { label: $t('cancel'), color: 'secondary', action: 'cancel' },
        { label: $t('delete'), color: 'danger', action: 'delete' },
      ]"
      @cancel="showDeleteConfirmDialog = false"
      @close="showDeleteConfirmDialog = false"
      @delete="deleteSharedStepItem"
    />

    <confirm-dialog
      v-model="showArchiveConfirmDialog"
      :title="$t('sharedStepPage.archiveConfirm', { action: isArchive ? $t('archive') : $t('unarchive'), name: selectedStep.name })"
      :description="isArchive ? $t('sharedStepPage.archiveDescription') : $t('sharedStepPage.unarchiveDescription')"
      :actions="[
        { label: $t('cancel'), color: 'secondary', action: 'cancel' },
        { label: isArchive ? $t('archive') : $t('unarchive'), color: 'primary', action: 'archive' },
      ]"
      @cancel="showArchiveConfirmDialog = false"
      @close="showArchiveConfirmDialog = false"
      @archive="changeActiveStatusSharedStepItem"
    />

    <confirm-dialog
      v-model="showBulkDeleteConfirmDialog"
      :title="$t('sharedStepPage.bulkDeleteConfirm', { count: selectedItems.length })"
      :description="$t('sharedStepPage.deleteDescription')"
      :actions="[
        { label: $t('cancel'), color: 'secondary', action: 'cancel' },
        { label: $t('delete'), color: 'danger', action: 'delete' },
      ]"
      @cancel="showBulkDeleteConfirmDialog = false"
      @close="showBulkDeleteConfirmDialog = false"
      @delete="bulkDeleteSharedSteps"
    />

    <confirm-dialog
      v-model="showBulkArchiveUnarchiveDialog"
      :title="$t('sharedStepPage.bulkArchiveConfirm', { count: selectedItems.length, action: tab === 'active' ? $t('archive') : $t('unarchive') })"
      :description="isArchive ? $t('sharedStepPage.archiveDescription') : $t('sharedStepPage.unarchiveDescription')"
      :actions="[
        { label: $t('cancel'), color: 'secondary', action: 'cancel' },
        { label: tab === 'active' ? $t('archive') : $t('unarchive'), color: 'primary', action: 'archive' },
      ]"
      @cancel="showBulkArchiveUnarchiveDialog = false"
      @close="showBulkArchiveUnarchiveDialog = false"
      @archive="bulkChangeStatusSharedSteps"
    />
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import makeSharedStepService from '@/services/api/sharedStep';
import Header from '@/components/Admin/SharedStep/Header';
import Placeholder from '@/components/base/Placeholder.vue';
import SharedStepTable from '@/components/Admin/SharedStep/SharedStepTable.vue';
import FilterDialog from '@/components/Admin/SharedStep/FilterDialog.vue';
import CreateUpdateDialog from '@/components/Admin/SharedStep/CreateUpdateDialog.vue';
import { formatDate } from '@/utils/util';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import ConfirmDialog from '@/views/Admin/SharedSteps/ConfirmDialog.vue';
import SharedStepDetail from '@/components/Admin/SharedStep/SharedStepDetail.vue';
import { handleNetworkStatusError } from '@/mixins/redirect';
import SearchIcon from '@/assets/svg/search-icon.svg';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import Loader from '@/components/base/Loader.vue';
import { sleep } from '@/utils/util';
import { mapGetters, mapActions } from 'vuex';

const { mapState } = createNamespacedHelpers('user');
let sharedStepService;

export default {
  name: 'SharedStep',

  components: {
    Header,
    SharedStepTable,
    Placeholder,
    FilterDialog,
    CreateUpdateDialog,
    ConfirmDialog,
    SharedStepDetail,
    SearchIcon,
    SettingsMenu,
    Loader,
  },

  mixins: [handleNetworkStatusError],

  data()
  {
    return {
      projectKey: this.$route.params.key,
      loading: false,
      isDetailMode: false,
      headers: [],
      loaderState: false,
      onFetchDataState: false,
      tableData: [],
      isColumnFilter: false,
      searchFilter: '',
      stepRangeFilter: [],
      referencedByRangeFilter: [],
      showCreateUpdateDialog: false,

      tab: 'active',
      selectedStep: {
        uid: '',
        name: '',
        steps: [],
      },
      showArchiveConfirmDialog: false,
      showDeleteConfirmDialog: false,
      showBulkDeleteConfirmDialog: false,
      showBulkArchiveUnarchiveDialog: false,
      isArchive: true,
      selectedItems: [],
      detailItem: '',

      originalTags: [],
      filteredTags: [],
      isLoading: false,
      errorMessage: '',
      currentIndex: 0,
    };
  },

  computed: {
    ...mapGetters({
      dynamicHeaders:'headers/dynamicHeaders'
    }),
    ...mapState(['currentAccount']),

    filteredSteps()
    {
      let filteredStepsData = this.tableData;

      if (this.tab) {
        filteredStepsData = filteredStepsData.filter(
          (item) => (this.tab === 'active' && !item.archivedAt) || (this.tab === 'archived' && item.archivedAt)
        );
      }

      if (this.searchFilter) {
        filteredStepsData = filteredStepsData.filter((item) =>
          item.name.toLowerCase().includes(this.searchFilter.toLowerCase())
        );
      }

      if (this.stepRangeFilter.length > 0) {
        filteredStepsData = filteredStepsData.filter(
          (item) => this.stepRangeFilter[0] <= item.steps.length && this.stepRangeFilter[1] >= item.steps.length
        );
      }

      if (this.referencedByRangeFilter.length > 0) {
        filteredStepsData = filteredStepsData.filter(
          (item) =>
            this.referencedByRangeFilter[0] <= item.steps.length && this.referencedByRangeFilter[1] >= item.steps.length
        );
      }

      return filteredStepsData;
    },
    filteredHeaders() {
      const filtered = this.filteredMenuHeaders.filter((header) => header.checked);
      return filtered;
    },
    filteredMenuHeaders()
    {
      const filtered = this.headers.filter((header) => header.value != 'actions');
      return filtered;
    },

    activeCount()
    {
      return this.tableData.filter((item) => !item.archivedAt).length;
    },

    archivedCount()
    {
      return this.tableData.filter((item) => !!item.archivedAt).length;
    },

    hasSteps()
    {
      return this.tableData.length > 0;
    },

    currentItem()
    {
      return this.detailItem || (this.filteredSteps.length > 0 ? this.filteredSteps[0] : null);
    },

    currentFilteredIndex()
    {
      return this.filteredSteps.findIndex((step) => step.uid === this.currentItem.uid);
    },
  },

  mounted()
  {
    this.init();
  },

  created()
  {
    if(!this.dynamicHeaders.sharedSteps) {
      this.initializeHeaders({ type: 'sharedSteps' });
    }
    this.headers = this.dynamicHeaders.sharedSteps;
    sharedStepService = makeSharedStepService(this.$api);
  },

  methods: {
    ...mapActions("headers", ['initializeHeaders']),
    async init() {
      this.loaderState = false;
      this.onFetchDataState = true;
      let loaderStateTimeout = null;

      loaderStateTimeout = setTimeout(() => {
        this.loaderState = true;
        this.onFetchDataState = false;
      }, 1000);

      try {
        await this.getSharedSteps();
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'templates' });
      } finally {
        clearTimeout(loaderStateTimeout); 
        const hideLoader = async () => {
          await sleep(1000); 
          this.loaderState = false; 
        };
        hideLoader(); 
        this.onFetchDataState = false;
      }
    },
    updateFilter(isDefault, stepRange, referenceRange)
    {
      if (isDefault == false) {
        this.stepRangeFilter = stepRange;
        this.referencedByRangeFilter = referenceRange;
      } else {
        this.stepRangeFilter = [];
        this.referencedByRangeFilter = [];
      }
      // this.filteredSteps();
    },
    formatCreatedAt(createdAt)
    {
      return formatDate(createdAt, 'MM/dd/yy');
    },

    handleInput(selectedItems)
    {
      this.selectedItems = selectedItems;
    },

    handleDeleteItem(item)
    {
      this.showDeleteConfirmDialog = true;
      this.selectedStep = item;
    },

    handleUnArchiveItem(item)
    {
      this.isArchive = false;
      this.showArchiveConfirmDialog = true;
      this.selectedStep = item;
    },

    handleArchiveItem(item)
    {
      this.isArchive = true;
      this.showArchiveConfirmDialog = true;
      this.selectedStep = item;
    },

    deleteSharedSteps()
    {
      this.showBulkDeleteConfirmDialog = true;
    },

    async bulkDeleteSharedSteps()
    {
      this.showBulkDeleteConfirmDialog = false;
      const sharedStepIds = this.selectedItems.map(item => item.uid);
      const payload = { sharedStepIds };
      try {
        const response = await sharedStepService.deleteSharedSteps(
          this.currentAccount.handle,
          this.projectKey,
          payload
        );
        if (response.status == 200) {
          showSuccessToast(this.$swal, 'deleteSuccess', { item: 'Shared step' });
          this.getSharedSteps();
        }
      } catch (err) {
        showErrorToast(this.$swal, 'deleteError', { item: 'Shared step' });
      }
    },

    handleChangeActiveStatuses(status)
    {
      this.isArchive = status;
      this.showBulkArchiveUnarchiveDialog = true;
    },

    async bulkChangeStatusSharedSteps()
    {
      this.showBulkArchiveUnarchiveDialog = false;
      const payload = this.selectedItems.map(item => ({
        id: item.uid,
        archived: this.tab != 'archived',
      }));
      try {
        const response = await sharedStepService.updateSharedSteps(
          this.currentAccount.handle,
          this.projectKey,
          { sharedSteps: payload }
        );
        if (response.status == 200) {
          showSuccessToast(this.$swal, this.tab != 'archived' ? 'archiveSuccess' : 'unarchiveSuccess', { item: 'Shared step' });
          this.getSharedSteps();
        }
      } catch (err) {
        showErrorToast(this.$swal, this.tab != 'archived' ? 'archiveError' : 'unarchiveError', { item: 'Shared step' });
      }
    },

    async changeActiveStatusSharedStepItem()
    {
      this.showArchiveConfirmDialog = false;
      const payload = [{
        id: this.selectedStep.uid,
        archived: this.tab != 'archived',
      }];
      try {
        const response = await sharedStepService.updateSharedSteps(
          this.currentAccount.handle,
          this.projectKey,
          { sharedSteps: payload }
        );
        if (response.status == 200) {
          showSuccessToast(this.$swal, this.tab != 'archived' ? 'archiveSuccess' : 'unarchiveSuccess', { item: 'Shared step' });
          this.getSharedSteps();
        }
      } catch (err) {
        showErrorToast(this.$swal, this.tab != 'archived' ? 'archiveError' : 'unarchiveError', { item: 'Shared step' });
      }
    },

    async updateSharedStep(sharedStep)
    {
      this.showCreateUpdateDialog = false;
      const payload = {
        name: sharedStep.name,
        description: sharedStep.description,
        expectedResult: sharedStep.expectedResult,
        steps: sharedStep.steps,
      };
      try {
        const response = await sharedStepService.updateSharedStep(
          this.currentAccount.handle,
          this.projectKey,
          sharedStep.uid,
          payload
        );
        if (response.status == 200) {
          showSuccessToast(this.$swal, 'updateSuccess', { item: 'Shared step' });
          this.getSharedSteps();
        }
      } catch (err) {
        showErrorToast(this.$swal, 'updateError', { item: 'Shared step' });
      }
    },

    async createSharedStep(sharedStep)
    {
      this.showCreateUpdateDialog = false;
      this.isLoading = true;
      const data = {
        name: sharedStep.name,
        steps: sharedStep.steps,
      };
      try {
        const response = await sharedStepService.createSharedStep(
          this.currentAccount.handle,
          this.projectKey,
          data
        );
        if (response.status == 200) {
          showSuccessToast(this.$swal, 'createSuccess', { item: 'Shared step' });
          this.getSharedSteps();
        } else {
          showErrorToast(this.$swal, 'createError', { item: 'Shared step' });
        }
      } catch (err) {
        showErrorToast(this.$swal, 'createError', { item: 'Shared step' });
      }
    },

    async deleteSharedStepItem()
    {
      this.showDeleteConfirmDialog = false;
      try {
        const response = await sharedStepService.deleteSharedStep(
          this.currentAccount.handle,
          this.projectKey,
          this.selectedStep.uid
        );
        if (response.status == 200) {
          showSuccessToast(this.$swal, 'deleteSuccess', { item: 'Shared step' });
          this.getSharedSteps();
        }
      } catch (err) {
        showErrorToast(this.$swal, 'deleteError', { item: 'Shared step' });
      }
    },

    handleClickRow(item)
    {
      this.isDetailMode = true;
      this.detailItem = item;
    },

    closeDetail()
    {
      this.isDetailMode = false;
    },

    onCreateSharedStep()
    {
      (this.selectedStep = {
        uid: '',
        name: '',
        steps: [],
      }),
        (this.showCreateUpdateDialog = true);
    },

    handleEditItem(item)
    {
      this.selectedStep = item;
      this.showCreateUpdateDialog = true;
    },

    async getSharedSteps()
    {
      this.isLoading = true;
      this.isDetailMode = false;
      this.detailItem = '';
      try {
        const response = await sharedStepService.getSharedSteps(
          this.currentAccount.handle,
          this.projectKey
        );
        if (response.status == 200) {
          this.tableData = response.data.sharedSteps;
        }
      } catch (err) {
        this.redirectOnError(err.response.status);
        showErrorToast(this.$swal, 'fetchError', { item: 'shared steps' });
      } finally {
        this.isLoading = false;
      }
    },

    updateFilterStatus(value)
    {
      this.tab = value;
      // this.filteredSteps();
    },

    navigateToPrevious()
    {
      if (this.currentFilteredIndex > 0) {
        this.detailItem = this.filteredSteps[this.currentFilteredIndex - 1];
      }
    },

    navigateToNext()
    {
      if (this.currentFilteredIndex < this.filteredSteps.length - 1) {
        this.detailItem = this.filteredSteps[this.currentFilteredIndex + 1];
      }
    },
  },
};
</script>

<style scoped>
p.mb-0 {
  font-size: 14px;
  color: #0c111d;
}

h5.used-in {
  font-size: 14px;
  color: #667085;
}
.action-btn-wrapper {
  position: sticky;
    bottom: 0;
    background-color: white;
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    z-index: 8;
}
</style>
